

export default {
  "app": {
    "all": "Alle",
    "verified": "Verifiziert",
    "not-verified": "Nicht verifiziert",
    "verify": "Verifizieren",
    "change": "Ändern",
    "finish": "Fertig",
    "info-tooltip": "Info",
    "footer-dekra-title": "DEKRA Arbeit Group",
    "footer-part-of": ", Teil von",
    "organization": "Organisation",
    "theme": "App-Thema",
    "cancel": "Abbrechen",
    "ok": "OK",
    "crop-and-save": "Zuschneiden und Speichern",
    "confirm": "Bestätigen",
    "org-settings": "Org. Einstellungen",
    "invite-user": "Benutzer einladen",
    "profile": "Profil",
    "settings": "Einstellungen",
    "logout": "Abmelden",
    "search": "Suche",
    "attributes-search": "Attribute suchen",
    'rate-ai-response': "Bewerten Sie die Antwort",
    "ai-search": "AI-Suche",
    "ai-search-placeholder": "Geben Sie hier Ihren Prompt ein und drücken Sie die Eingabetaste",
    "ai-search-text": "Erhalten Sie die relevantesten Ergebnisse für Ihre Suche. Probieren Sie es aus ↓",
    "ai-search-min-length": "Prompt muss mindestens 10 Zeichen lang sein",
    "ai-search-max-length": "Prompt darf maximal 200 Zeichen lang sein",
    "switch-to": "Wechseln zu",
    "sort-by": "Sortieren nach",
    "search-by-attribute": "Nach Attribut suchen",
    "ascending": "Aufsteigend",
    "ascending-short": "Asc",
    "descending": "Absteigend",
    "descending-short": "Desc",
    "filter-btn": "Anwenden",
    "reset-filter": "Filter zurücksetzen",
    "info": "Info",
    "not-this-user": "Nicht dieser Benutzer?",
    "continue": "Weiter",
    "enter-code": "Gib den Code ein",
    "clear": "Löschen",
    "save": "Speichern",
    "add": "Hinzufügen",
    "add-new": "Neu hinzufügen",
    "no-data": "Keine Daten verfügbar",
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "back": "Zurück",
    "loading": "Laden...",
    "collapse": "Einklappen",
    "send": "Senden",
    "version": "Anwendungsversion",
    "power-mode-btn": "Energiesparmodus",
    "go-back": "Zurück gehen",
    "my-account": "Mein Konto",
    "fullscreen": "Vollbild",
    "grid-label": "Grid",
    "table-label": "Tabelle",
    "grid-tooltip": "Grid-Anzeige",
    "table-tooltip": "Tabellenansicht",
    "download": "Herunterladen",
    "export": "Als CSV exportieren",
    "copied-to-clipboard": "In die Zwischenablage kopiert",
    "work-in-progress": "In Arbeit"
  },
  "auth": {
    "login": "Anmelden",
    "submit": "Einreichen",
    "back-to-login": "Zurück zur Anmeldung",
    "forgot-password": "Passwort vergessen",
    "reset-password": "Passwort zurücksetzen",
    "reset-password-expired": "Passwort zurücksetzen abgelaufen",
    "set-password": "Passwort festlegen",
    "set-password-expired": "Passwort festlegen abgelaufen",
  },
  "nav-bar": {
    "home-title": "Startseite",
    "home-caption": "App-Dashboard",
    "contacts-manager-title": "Kontaktmanager",
    "contacts-manager-caption": "Kontakte in Organisation verwalten",
    "contacts-title": "Kontakte",
    "contacts-caption": "Kontaktliste",
    "imported-files-title": "Importierte Dateien",
    "imported-files-caption": "Importierte Kontaktdateien",
    "communication-manager-title": "Kommunikationsmanager",
    "communication-manager-caption": "Kommunikation in Organisation verwalten",
    "communication-title": "Kommunikation",
    "communication-caption": "Kommunikationsliste",
    "communication-settings-title": "Kommunikationseinstellungen",
    "communication-settings-caption": "Kommunikationseinstellungen",
    "communication-chat-title": "Chat",
    "communication-chat-caption": "WhatsApp/Viber chat",
    "pools-title": "Pools",
    "custom-attributes-title": "Benutzerdefinierte Attribute",
    "tags-title": "Tags",
    "content-creator-title": "Inhaltseditor",
    "form-builder-title": "Formulareditor",
    "page-builder-title": "Seiteneditor",
    "users-manager-title": "Benutzerverwaltung",
    "users-manager-caption": "Benutzer in Organisation verwalten",
    "users-title": "Benutzer",
    "users-caption": "Benutzerliste",
    "roles-title": "Rollen",
    "organizations-title": "Organisationen",
    "settings-title": "Einstellungen",
    "settings-caption": "Account-Einstellungen",
    "collapse-title": "Einklappen",
    "collapse-caption": "Seitenleiste einklappen",
    "power-mode-title": "Admin-Panel",
    "power-mode-caption": "Steuerungspanel"
  },
  "app-fields": {
    "first-name-label": "Vorname",
    "last-name-label": "Nachname",
    "username-label": "Benutzername",
    "email-label": "E-Mail",
    "phone-label": "Telefonnummer",
    "bio-label": "Biografie (Schreiben Sie eine kurze Einführung)"
  },
  "delete-modal": {
    "confirm-delete": "Löschen bestätigen",
    "delete-text": "Sind Sie sicher, dass Sie dieses Element löschen möchten?",
    "confirm-restore": "Wiederherstellen bestätigen",
    "restore-text": "Sind Sie sicher, dass Sie dieses Element wiederherstellen möchten?"
  },
  "reload-modal": {
    "confirm-reload": "Seite neu laden bestätigen",
    "reload-text": "Sind Sie sicher, dass Sie die Seite neu laden möchten?"
  },
  "welcome-page": {
    "welcome-title": "Willkommen bei",
    "powered-by": "Bereitgestellt von"
  },
  "users": {
    "invite-user": "Benutzer einladen",
    "users-list-title": "Benutzer in Organisation",
    "users-list": "Benutzer",
    "invite-user-title": "Benutzer in Organisation einladen",
    "filters": "Filter",
    "invite-user-page": {
      "email-info": "Geben Sie die E-Mail-Adresse der Person ein, die Sie einladen möchten",
      "username-info": "Geben Sie einen Benutzernamen für diese Person an",
      "roles": "Rollen zuweisen",
      "roles-info": "Wählen Sie die Rollen aus, die am besten den Aufgaben des Benutzers in dieser Organisation entsprechen.",
      "not-valid-email": "E-Mail ist nicht gültig",
      "not-valid-phone": "Telefonnummer ist nicht gültig",
      "not-valid-username": "Der Benutzername ist nicht gültig. Bitte verwenden Sie nur Kleinbuchstaben, Zahlen und Sonderzeichen.",
      "user-in-org": "Dieser Benutzer ist bereits Mitglied der Organisation.",
      "success-page": "Sie haben den Benutzer erfolgreich zu dieser Organisation eingeladen."
    },
    "loading-users": "Benutzer werden geladen...",
    "admin-title": "Administrator",
    "remove-user-from-org": "Benutzer aus Organisation entfernen",
    "abort-user-invitation": "Benutzereinladung abbrechen",
    "resend-invitation": "Einladung erneut senden",
    "invitation-accepted": "Einladung angenommen",
    "invitation-declined": "Einladung abgelehnt",
    "invitation-pending": "Einladung ausstehend",
    "edit-user": "Benutzer bearbeiten",
    "user-profile": "Benutzerprofil",
    "edit": {
      "email-label": "Benutzer-E-Mail",
      "username-label": "Benutzer-Benutzername"
    },
    "last-updated": "Zuletzt aktualisiert"
  },
  "contacts": {
    "contacts-list-title": "Kontakte in Organisation",
    "contacts-list": "Kontakte",
    "create-contact": "Kontakt erstellen",
    "create-contact-title": "Kontakt erstellen",
    "contacts-import-title": "Kontakte importieren",
    "import-bulk": "Massenimport",
    "filters": "Filter",
    "delete-contact": "Kontakt löschen",
    "restore-contact": "Kontakt wiederherstellen",
    "loading-contacts": "Kontakte werden geladen...",
    "edit-contact": "Kontakt bearbeiten",
    "contact-profile": "Kontaktprofil",
    "information-source": "Informationsquelle",
    "last-updated": "Zuletzt aktualisiert",
    "tab-details-title": "Details",
    "tab-custom-attributes-title": "Benutzerdefinierte Attribute",
    "tab-messages-title": "Nachrichten",
  },
  "roles": {
    "title": "Rollen",
    "table": {
      "name": "Name",
      "display-name": "Anzeigename",
      "description": "Beschreibung"
    }
  },
  "profile": {
    "reset-password-title": "Passwort zurücksetzen",
    "reset-password-info": "Letzte Passwortänderung um ",
    "reset-password-btn": "Passwort zurücksetzen",
    "reset-password-label-new-password": "Neues Pass",
    "reset-password-label-confirm-password": "Passwort bestätigen",
    "reset-password-new-password-not-valid": "Das Passwortfeld ist erforderlich und muss mindestens 8 Zeichen lang sein.",
    "reset-password-new-password-confirmation-not-valid": "Das Passwortbestätigungsfeld ist erforderlich und muss mit dem Passwortfeld übereinstimmen.",
    "reset-password-error": "Überprüfen Sie Ihre Telefonnummer, um das Passwort zu ändern",
    "reset-password-form-title": "Neues Passwort erstellen",
    "reset-password-form-subtitle": "Setzen Sie ein starkes Passwort, um Ihr Konto sicher zu halten.",
    "reset-password-hint": "Muss mindestens 8 Zeichen lang sein, es muss auch mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
    "reset-password-succes-title": "Erfolgreiche Passwortänderung!",
    "reset-password-succes-info": "Sie können sich jetzt mit Ihrem neuen Passwort in Ihr Konto einloggen.",
    "reset-password-save-btn": "Speichern",
    "first-name-not-valid": "Vorname ist nicht gültig",
    "last-name-not-valid": "Nachname ist nicht gültig",
    "organization-tab-org-switch-title": "Organisation wechseln",
    "organization-tab-org-switch-tooltip": "Zu dieser Organisation wechseln",
    "organization-tab-org-leave-tooltip": "Diese Organisation verlassen",
    "organization-invitation-accept": "Akzeptieren",
    "organization-invitation-decline": "Ablehnen",
    "organization-tab-current-org": "Aktuelle Organisation",
    "tab-details-title": "Details",
    "tab-security-title": "Sicherheit",
    "tab-organizations-title": "Organisationen",
    "header-updated-at": "Aktualisiert am:",
    "add-phone-number": "Telefonnummer hinzufügen",
    "add-email": "E-Mail hinzufügen",
    "roles-label": "Rollen"
  },
  "otp-page": {
    "otp-title": "Einmalige Code-Authentifizierung",
    "otp-subtitle": "Ein Bestätigungscode wurde gesendet an ",
    "keycloak-otp-subtitle": "Öffnen Sie eine der Mobile Authenticator-Apps, finden Sie Ihr Konto und geben Sie den Einmalcode ein, der von der Anwendung bereitgestellt wird.",
    "otp-info": "Bitte geben Sie den 6-stelligen Code ein.",
    "otp-remaining-time": "Verbleibende Zeit: ",
    "otp-verify-btn": "Verifizieren",
    "otp-verify-problems-info": "Haben Sie Probleme beim Verifizieren Ihrer Telefonnummer?",
    "otp-resend-code-text": "Bestätigungscode erneut senden",
    "verification-code-label": "Einmaliger Code",
    "cooldown-title": "Abkühlphase...",
    "resend-cooldown-info": "Warten auf Abkühlphase..."
  },
  "organizations": {
    "info-title": "Einladung zu",
    "info-subtitle": "Sie wurden eingeladen, sich einer",
    "info-inviter-label": "Einladung gesendet von:",
    "info-roles-assigned-label": "Zugewiesene Rollen:",
    "info-roles-assigned-subtitle": "zugewiesene Rollen"
  },
  "helpers": {
    "back-home-btn": "Zurück zur Startseite",
    "no-org-page-title": "Sie sind kein Mitglied einer Organisation.",
    "message-field-label": "Senden Sie uns eine Nachricht, wenn Sie etwas brauchen.",
    "message-placeholder": "Nachricht",
    "no-permistions-page-title": "Zugriff verweigert!",
    "no-permissions-page-subtitle": "Sie haben nicht die Berechtigungen, diese Seite anzuzeigen. Kontaktieren Sie den Administrator für weitere Informationen!",
    "success-action": "Sie haben die Aktion erfolgreich abgeschlossen.",
    "error-500-title": "Diese Seite funktioniert nicht",
    "error-500": "Fehler 500",
    "error-404": "Fehler 404",
    "error-401": "Fehler 401",
    "error-503": "Fehler 503",
    "error-503-title": "Dienst nicht verfügbar",
    "error-503-subtitle": "Entschuldigung, die Seite ist vorübergehend nicht verfügbar.",
    "error-500-subtitle": "Wir entschuldigen uns und arbeiten an dem Problem. Bitte versuchen Sie es später erneut.",
    "error-404-title": "Etwas ist schiefgelaufen",
    "error-404-subtitle": "Entschuldigung, wir konnten diese Seite nicht finden."
  },
  "settings": {
    "general-title": "Allgemein",
    "appearance-title": "Erscheinungsbild",
    "theme-title": "Thema auswählen",
    "theme-subtitle": "Anpassung gemäß Ihren Vorlieben.",
    "theme-toggle": "Thema umschalten",
    "theme-hint": "Wählen Sie das Thema aus, das Sie verwenden möchten.",
    "grid-title": "Wählen Sie Ihren Anzeigestil",
    "grid-subtitle": "Anpassung nach Ihren Vorlieben.",
    "grid-toggle": "Wechseln Sie die Anzeige zwischen Gitter/Tabelle",
    "grid-hint": "Wählen Sie den Anzeigestil, den Sie verwenden möchten.",
    "primary-color-title": "Primärfarbe",
    "primary-color-subtitle": "Wählen Sie Ihre primäre Farbe aus.",
    "primary-color-hint": "Die ausgew ählte Farbe wird in der gesamten App angezeigt.",
    "date-format-title": "Datumsformat",
    "date-format-subtitle": "Wählen Sie das Datumsformat aus, das Sie verwenden möchten.",
    "date-format-hint": "Das ausgewählte Datumsformat wird in der gesamten App angezeigt.",
    "time-format-title": "Zeitformat",
    "time-format-subtitle": "Wählen Sie das Zeitformat aus, das Sie verwenden möchten.",
    "time-format-hint": "Das ausgewählte Zeitformat wird in der gesamten App angezeigt.",
    "notification-title": "Benachrichtigung",
    "email-notification-title": "E-Mail-Benachrichtigung",
    "email-notification-subtitle": "Substance kann Ihnen E-Mail-Benachrichtigungen für neue Direktnachrichten senden",
    "chat-notification-title": "Chat-Benachrichtigung",
    "chat-notification-subtitle": "Nachrichten, Erwähnungen und Reaktionen.",
    "show-preview-message": "Nachrichtenvorschau anzeigen",
    "play-sound-notification": "Ton für eingehende Benachrichtigungen abspielen",
    "templates-title": "Vorlagen",
    "language-title": "Sprache",
    "language-subtitle": "Verwalten Sie Ihre Spracheinstellungen",
    "language-hint": "Wählen Sie die Sprache aus, die Sie verwenden möchten.",
    "time-zone-title": "Zeitzone",
    "time-zone-subtitle": "Verwalten Sie Ihre Zeitzone",
    "time-zone-hint": "Wählen Sie Ihre Zeitzone aus."
  },
  "home": {
    "welcome-title": "Willkommen zu {org}, {city}",
    "welcome-subtitle": "Lass uns mit einer Tour durch dein Dashboard beginnen. Entdecke, wie du das Beste aus deinem Arbeitsbereich machen kannst.",
    "how-to": "Wie man?",
    "send-msg-btn": "Nachricht senden",
    "create-page-btn": "Seite erstellen",
    "import-contacts-btn": "Kontakte importieren",
    "search-contacts-btn": "Kontakte suchen",
    "complete-your-profile": "Vervollständige dein Profil",
    "complete-your-profile-text": "Profilinformationen aktualisieren",
    "verify-phone-number": "Telefonnummer bestätigen",
    "verify-email": "Bestätige deine E-Mail",
    "add-user-avatar": "Benutzer-Avatar hinzufügen",
    "update-your-bio": "Aktualisiere deine Biografie",
    "add-first-name": "Vorname hinzufügen",
    "add-last-name": "Nachname hinzufügen",
    "action-box-title": "Aktionsfeld",
    "action-box-pending": "Ausstehende Einladungen",
    "action-box-invited-by": "Eingeladen von ",
    "insights-title": "Einblicke",
    "insights-add": "Einblick hinzufügen",
    "teammates-title": "Teammitglieder",
    "teammates-subtitle": "Laden Sie Ihre Teammitglieder ein",
    "teammates-invite-button": "Einladen",
    "teammates-list-button": "Konten verwalten"
  },
  "form": {
    "add-new-attribute-title": "Neues Attribut hinzufügen",
    "select-or-type-new-attribute": "Wählen oder geben Sie ein neues Attribut ein"
  },
  "table-columns": {
    "id": "ID",
    "category": "Kategorie",
    "status": "Status",
    "short-url": "Kurz-URL",
    "message": "Nachricht",
    "title": "Titel",
    "button-title": "Schaltflächentitel",
    "button-text": "Schaltflächentext",
    "button-action": "Schaltflächenaktion",
    "message-template": "Nachrichtenvorlage",
    "chat-link": "Chat-Link",
    "inactive-time-span": "Inaktive Zeitspanne(in Tagen)",
    "name": "Name",
    "display-name": "Anzeigename",
    "destinations-count": "Anzahl der Ziele",
    "records-count": "Anzahl der Datensätze",
    "failed-records-count": "Anzahl der fehlgeschlagenen Datensätze",
    "finished-at": "Beendet am",
    "first-name": "Vorname",
    "last-name": "Nachname",
    "username": "Benutzername",
    "email": "E-Mail",
    "phone-number": "Telefonnummer",
    "sent": "Gesendet",
    "delivered": "Zugestellt",
    "undelivered": "Nicht zugestellt",
    "rejected": "Abgelehnt",
    "pending": "Ausstehend",
    "expired": "Abgelaufen",
    "undeliverable": "Nicht zustellbar",
    "delivered-status": "Zugestellter Status",
    "status-message": "Statusnachricht",
    "status-reason": "Statusgrund",
    "cost": "Kosten",
    "delivery-status": "Zustellstatus",
    "status-breakdown": "Statusaufschlüsselung",
    "city": "Stadt",
    "country": "Land",
    "address": "Adresse",
    "birthday": "Geburtstag",
    "gender": "Geschlecht",
    "language": "Sprache",
    "channel": "Kanal",
    "subject": "Betreff",
    "basic-info": "Grundlegende Informationen*",
    "additional-info": "Zusätzliche Informationen",
    "custom-attributes": "Benutzerdefinierte Attribute",
    "phone-number-verified-at": "Telefonnummer verifiziert am",
    "add-manualy-phone-number": "Telefonnummer manuell hinzufügen",
    "add-manualy-email": "E-Mail manuell hinzufügen",
    "sender-id": "Absender-ID",
    "created-at": "Erstellt am",
    "updated-at": "Aktualisiert am",
    "sent-at": "Gesendet am",
    "actions": "Aktionen"
  },
  "bulk-import": {
    "example-headers": {
      "column-name": "Name",
      "column-desc": "Beschreibung",
      "column-format": "Format",
      "column-example": "Beispiel",
      "column-required": "Erforderlich",
      "column-add-new": "Neu hinzufügen"
    },
    "import-process-initiated-by": "Importvorgang initiiert von:",
    "import-file": "Datei importieren",
    "import-finished-at": "Import beendet am",
    "import-failed-at": "Import fehlgeschlagen am",
    "import-created-at": "Import erstellt am",
    "import-failed-file": "Fehlgeschlagene Datei",
    "import-records-count": "Anzahl der importierten Datensätze",
    "import-failed-records-count": "Anzahl der fehlgeschlagenen Datensätze",
    "import-details": "Details importieren",
    "contacts-import-list": "Importierte Dateien",
    "contacts-import-list-title": "Importierte Kontaktdateien",
    "contacts-import-details": "Details importieren",
    "contacts-import-details-title": "Details zum Dateiimport",
    "update-duplicates": "Aktualisieren Sie Duplikate",
    "skip-rows": "Überspringe die ersten Zeilen der Datei",
    "skip-rows-info": "{count} Zeilen der Datei überspringen | {count} Zeile der Datei überspringen | {count} Zeilen der Datei überspringen",
    "first-row-is-header": "Die erste Zeile ist eine Kopfzeile",
    "confirm-import": "Daten importieren",
    "import-text": "Sind Sie sicher, dass Sie die Daten importieren möchten?",
    "import-notification": "hre Daten wurden zur Verarbeitung gesendet. Sie erhalten eine Benachrichtigung, wenn der Vorgang abgeschlossen ist.",
    "download": "Laden Sie das CSV-Beispiel herunter",
    "drop-file": "Datei hier ablegen oder",
    "browse": "Durchsuchen",
    "file-size-error": "Dateigröße überschritten. Maximale Dateigröße beträgt 1 MB",
    "file-type-error": "Dateityp nicht unterstützt. Unterstützte Dateitypen: .csv, .xlsx",
    "only-one-file": "Nur eine Datei kann hochgeladen werden",
    "column-mapping-error": "Bitte ordnen Sie alle Spalten zu, bevor Sie fortfahren",
    "step-1": "Erste Schritte",
    "step-1-title": "Bereiten Sie sich auf den Datenimport vor",
    "step-1-subtitle": "Die folgenden Spalten sind erforderlich, der Rest ist optional",
    "step-2": "Datei hochladen",
    "step-2-title": "Datei zum Hochladen auswählen",
    "step-2-subtitle": "Erlaubte Dateitypen: .csv, .xlsx",
    "step-3": "Spaltenzuordnung",
    "step-3-title": "Kopfzeilenspalten zuordnen",
    "step-3-subtitle": "Bestätigen, dass jede Spalte richtig zugeordnet ist",
    "step-4": "Datenvorschau und Import",
    "step-4-title": "Spaltenwerte prüfen",
    "step-4-subtitle": "Bestätigen, dass jeder Wert richtig zugeordnet ist",
  },
  "communication": {
    "brodcast-message": {
      "step-1": "Kanal",
      "step-1-title": "Übertragungskanal auswählen",
      "step-2": "Kontakte",
      "step-2-title": "Kontakte filtern",
      "step-2-selected": "Ausgewählte Kontakte",
      "step-3": "Nachricht",
      "step-3-title": "Nachricht verfassen",
      "step-3-preview": "Nachricht in Vorschau anzeigen",
      "step-4": "Zusammenfassung",
      "step-4-title": "Zusammenfassung der Sendung",
      "step-4-selected-channel": "Ausgewählter Kanal",
      "step-4-desired-communication-channel": "Gewünschter Kommunikationskanal",
      "step-4-total-destinations-count": "Gesamtanzahl der Ziele",
      "step-4-broadcast-maximum-reach": "Maximale Reichweite der Übertragung",
      "step-4-required": "Erforderlich",
      "step-4-name-your-bulk-communication": "Benennen Sie Ihre Massenkommunikation",
      "step-4-communication-name-for-reports": "Kommunikationsname für Berichte",
      "confirm-send": "Senden bestätigen",
      "send-text": "Sind Sie sicher, dass Sie diese Nachricht senden möchten?",
      "send-notification": "Ihre Nachricht wurde erfolgreich gesendet.",
    },
    "navigation": {
      "auto-reply-messages": "Auto-Reply-Nachrichten",
      "send-seen-status": "Gesehenen Status senden",
      "message-templates": "Nachrichtenvorlagen",
      "chat-links": "Chat-Links"
    },
    "settings": {
      "auto-reply-title": "Auto-Reply-Nachrichten",
      "auto-reply-subtitle": "Erstellen und verwalten Sie Auto-Reply-Nachrichten",
      "message-template-title": "Nachrichtenvorlagen",
      "chat-link-title": "Chat-Link erstellen",
      "hours": "Stunden",
      "delete-chat-link": "Chat-Link löschen",
      "restore-chat-link": "Chat-Link wiederherstellen",
      "select-message-template": "Nachrichtenvorlage auswählen",
      "select-message-template-label": "Nachrichtenvorlage auswählen",
      "delete-message-template": "Nachrichtenvorlage löschen",
      "restore-message-template": "Nachrichtenvorlage wiederherstellen",
    },
    "tab-details-title": "Details",
    "tab-statistics-title": "Statistiken",
    "tab-messages-title": "Nachrichten",
    "tab-logs-title": "Protokolle",
    "chat-expired": "24 Stunden Chatzeit abgelaufen. Starten Sie den Chat erneut, indem Sie eine Nachrichtenvorlage senden.",
    "create-new-template": "Neue Vorlage erstellen",
    "select-template": "Vorlage auswählen",
    "search-chat-contacts": "Chat-Kontakte suchen",
    "open": "Öffnen",
    "pending": "Ausstehend",
    "closed": "Geschlossen",
    "open-tooltip": "Sie können diesen Kontakten eine direkte Nachricht senden",
    "pending-tooltip": "Konversation ist ausstehend. Der Benutzer muss zuerst eine Nachricht senden oder auf Ihre Nachrichtenvorlage antworten.",
    "closed-tooltip": "Konversation ist geschlossen. Um den Chat erneut zu starten, senden Sie eine Nachrichtenvorlage.",
    "chat-contact-info": "Kontaktinformationen",
    "communication-list-title": "Kommunikation",
    "communication-list": "Kommunikation",
    "conversation-not-selected": "Wählen Sie eine Konversation aus, um Nachrichten anzuzeigen",
    "create-communication-title": "Kommunikation erstellen",
    "create-communication": "Kommunikation erstellen",
    "show-communication-title": "Kommunikation anzeigen",
    "show-communication": "Kommunikation anzeigen",
    "communication-settings-list-title": "Kommunikationseinstellungen",
    "communication-settings-list": "Kommunikationseinstellungen",
    "show-communication-settings-title": "Kommunikationseinstellungen anzeigen",
    "show-communication-settings": "Kommunikationseinstellungen anzeigen",
    "communication-chat-list-title": "Chat",
    "communication-chat-list": "Chat",
    "filters": "Filters",
    "loading-communication": "Kommunikation wird geladen...",
    "delete-communication": "Kommunikation löschen",
    "restore-communication": "Kommunikation wiederherstellen",
    "send-message": "Nachricht senden",
    "send-message-title": "Nachricht senden",
    "send-message-subtitle": "Senden Sie eine Nachricht an einen Benutzer",
    "message-placeholder": "Nachricht",
    "message-sent": "Nachricht gesendet",
    "message-sent-info": "Ihre Nachricht wurde erfolgreich gesendet.",
    "message-sent-error": "Fehler beim Senden der Nachricht",
    "message-sent-error-info": "Ihre Nachricht konnte nicht gesendet werden. Bitte versuchen Sie es erneut.",
  }
}
